<template>
	<el-card>
		<div class="d-flex flex-row flex-wrap">
			<form-item label="国家:" style="width:300px;">
				<el-select v-model="queryForm.countryCode" size="mini" clearable class="w-100" filterable>
					<el-option v-for="country in countries" :label="country.name" :value="country.code">
						<div class="d-flex flex-row align-items-center justify-content-between">
							<div>{{country.name}}</div>
							<div>{{country.code}}</div>
						</div>
					</el-option>
				</el-select>
			</form-item>
			<form-item label="语言:" style="width:200px;">
				<el-select v-model="queryForm.languageCode" size="mini" clearable filterable>
					<el-option v-for="language in shortLanguages" :label="language" :value="language"></el-option>
				</el-select>
			</form-item>
			<form-item v-if="false" label="发布状态:" style="width:200px;">
				<el-select v-model="queryForm.active" size="mini" clearable="">
					<el-option label="开启" :value="true"></el-option>
					<el-option label="暂停" :value="false"></el-option>
				</el-select>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" @click="visible.add=true">添加配置</el-button>
			</form-item>
		</div>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<alert v-if="multipleSelection.length > 0">
			已选择 <span class="text-primary">{{multipleSelection.length}}</span> 个。
			<el-dropdown @command="handleCommand">
			      <span class="text-primary">
			        批量操作
			      </span>
			      <el-dropdown-menu slot="dropdown">
			        <el-dropdown-item command="active">发布</el-dropdown-item>
			        <el-dropdown-item command="deactive">取消发布</el-dropdown-item>
			      </el-dropdown-menu>
			    </el-dropdown>
		</alert>
		<gg-country-language-table class="mt-3 w-100" :tableData="tableData" @handleSelectionChange="handleSelectionChange" @changePublish="changeActive" @editRow="editRow"></gg-country-language-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose" style="min-width:900px;">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true" label-width="100px">
				<el-form-item label="国家缩写:" prop="countryCode">
					<el-select v-model="form.countryCode" placeholder="请选择2位大写字母,示例:US" class="w-100" filterable>
						<el-option v-for="country in countries" :label="country.name" :value="country.code">
							<div class="d-flex flex-row align-items-center justify-content-between">
								<div>{{country.name}}</div>
								<div>{{country.code}}</div>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="语言缩写:" prop="languageCode">
					<el-select v-model="form.languageCode" placeholder="请选择2位大写字母,示例:EN" class="w-100" filterable>
						<el-option v-for="language in shortLanguages" :label="language" :value="language"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="语言全称:" prop="languageFullName">
					<el-select v-model="form.languageFullName" placeholder="请选择,示例:English(All)" class="w-100" filterable>
						<el-option v-for="language in fullLanguages" :label="language" :value="language"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="doCreateOrUpdate">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from 'vuex'
	import GgCountryLanguageTable from '@/components/google-contents/gg-country-language-table.vue'
	import config from './minixs/index.js'
	const InitialForm = {
		countryCode: '',
		languageCode: '',
		languageFullName: ''
	}
	export default {
		mixins:[config],
		components: {
			GgCountryLanguageTable
		},
		data() {
			return {
				queryForm: {
					countryCode: '',
					languageCode: '',
					active: ''
				},
				tableData: [],
				q: {},
				visible: {
					add: false
				},
				form: {...InitialForm},
				rules: {
					countryCode: [{
						required: true,
						message: '国家缩写不能为空'
					}],
					languageCode: [{
						required: true,
						message: '语言缩写不能为空'
					}],
					languageFullName: [{
						required: true,
						message: '语言全称不能为空'
					}]
				},
				namespace: 'google-contents-language'
			}
		},
		computed: {
			...mapGetters({
				countries: 'google/countries',
				shortLanguages: 'google/shortLanguages',
				fullLanguages:'google/fullLanguages'
			}),
			
		},
		mounted() {
			this.$store.dispatch('google/getCountries')
			this.$store.dispatch('google/getShortLanguages')
			this.$store.dispatch('google/getFullLanguages')
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = {...InitialForm}
				}
			}
		}
	}
</script>

<style>
</style>

<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect">
		<el-table-column type="selection" width="55">
		</el-table-column>
		<el-table-column label="站点" prop="channelCode" min-width="100">
		</el-table-column>
		<el-table-column label="国家缩写" prop="countryCode" min-width="120">
		</el-table-column>
		<el-table-column label="语言缩写" prop="languageCode" min-width="120">
		</el-table-column>
		<el-table-column label="国家全称" prop="countryName" min-width="120">
		</el-table-column>
		<el-table-column label="国家-GG ID" prop="countryGGId" min-width="150">
		</el-table-column>
		<el-table-column label="语言全称" prop="languageFullName" min-width="180">
		</el-table-column>
		<el-table-column label="语言-GG ID" prop="languageGGId" min-width="180">
		</el-table-column>
		<el-table-column label="发布状态" min-width="150">
			<template slot-scope="scope">
				<el-switch v-model="scope.row.active" @change="$emit('changePublish', scope.row)"></el-switch>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="100">
			<template slot-scope="scope">
				<el-button type="text" @click="$emit('editRow', scope.row)">编辑</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		}
	}
</script>

<style>
</style>
